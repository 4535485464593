<template>
  <div class="main-content">
    <div class="blue-text title">
      <font-awesome-icon
        class="mr-3"
        :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
        @click="$router.go(-1)"
      />
      <span>デザイン詳細</span>
      </div>
    <div class="bl_content">
      <div id="beer-slider" class="beer-slider" data-beer-label="before">
        <img class="image" :src="imageDetail.file_url" alt="before image">
        <div class="beer-reveal" data-beer-label="after">
          <img class="image" :src="imageDetail.result_file_url" alt="after image">
        </div>
      </div>
      <div class="bl_image_text">
        <div class="bl_link mb-3">
          <a class="mr-3 d-flex align-items-center" :href="'https://twitter.com/share?url='+currentPath" rel="nofollow noopener" target="_blank">
            <img class="social_img" src="/img/icons/social/icon-twitter-circle.png" alt="icon-twitter-circle"/>
          </a>
          <a class="mr-3 d-flex align-items-center" :href="'https://www.facebook.com/share.php?u='+currentPath" rel="nofollow noopener" target="_blank">
            <img class="social_img" src="/img/icons/social/icon-facebook-circle.png" alt="icon-facebook-circle"/>
          </a>
          <button class="btn btn-copy blue white-text" v-clipboard:copy="currentPath" v-clipboard:success="onCopy">Copy URL</button>
        </div>
        <div class="bl_text">
          <div class="font-weight-bold mb-2">{{ imageDetail.title }}</div>
          <div class="mb-2">{{ imageDetail.text }}</div>
          <div>{{ imageDetail.tag }}</div>
        </div>
      </div>
    </div>
    <div class="btn_edit">
      <button class="btn btn-ds dark-blue white-text" v-waves.light @click="openEditModal()">編集</button>
    </div>

    <!-- Edit Modal -->
    <b-modal
      id="edit"
      title="デザイン詳細編集" 
      title-class="modal_title"
      size="lg"
      centered
      header-bg-variant="primary"
      header-text-variant="white"
      hide-header-close
      body-class="modal_body"
      hide-footer
    >
      <form @submit="update">
        <div class="mb-3">
          <p class="m-0 font-weight-bold">タイトル</p>
          <input
            class="form-control shadow-1"
            type="text"
            minlength="1"
            maxlength="100"
            v-model="title"
            placeholder="タイトルを入力してください"
            required
          />
        </div>
        <div class="mb-3">
          <p class="m-0 font-weight-bold">詳細</p>
          <b-form-textarea
            v-model="text"
            class="form-area shadow-1"
            placeholder="詳細を入力してください"
            rows="5"
            max-rows="5"
          ></b-form-textarea>
        </div>
        <div class="mb-3">
          <p class="m-0 font-weight-bold">タグ</p>
          <input
            class="form-control shadow-1"
            type="text"
            minlength="1"
            maxlength="100"
            v-model="tag"
            v-on:keyup="formMessage = null"
            placeholder="タグを入力してください"
          />
        </div>
        <div class="mb-3">
          <p class="m-0 font-weight-bold">都道府県</p>
          <b-form-select
            class="shadow-1"
            v-model.number="prefecture_id"
          >
            <b-form-select-option value=0 disabled>都道府県を選択してください</b-form-select-option>
            <b-form-select-option :value="prefecture.id" v-for="(prefecture, index) in prefectures" :key="index">{{ prefecture.name }}</b-form-select-option>
          </b-form-select>
        </div>
        <div class="mb-3">
          <p class="m-0 font-weight-bold">間取り</p>
          <b-form-select
            class="shadow-1"
            v-model.number="house_layout_id"
          >
            <b-form-select-option value=0 disabled>間取りを選択してください</b-form-select-option>
            <b-form-select-option :value="layout.id" v-for="(layout, index) in houseLayouts" :key="index">{{ layout.name }}</b-form-select-option>
          </b-form-select>
        </div>
        <div class="bl_modal_btn">
          <button class="btn btn-ds light-grey white-text" type="button" v-waves.light @click="closeEditModal()">キャンセル</button>
          <button class="btn btn-ds dark-blue white-text ml-5" type="submit" v-waves.light>OK</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script src="https://unpkg.com/beerslider/dist/BeerSlider.js"></script>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCsWywCbXFr1Kju-h-XEc_46MndAqpBB9I"></script>

<script>
import { globalService_GetDesignDetail, globalService_GetHouseLayout, globalService_GetAllPrefectures } from '../../services/global';
import { cus_stagingService_PostDesignDetail } from '../../services/customer/staging';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Staging | Design Detail',
    };
  },
  data() {
    return {
      currentPath: null,
      imageDetail: {},
      houseLayouts: [],
      prefectures: [],
      title: null,
      text: null,
      tag: null,
      prefecture_id: 0,
      house_layout_id: 0,
    };
  },
  created(){
    this.getImageDetail(this.$route.params.id);
    this.getHouseLayout();
    this.getPrefectures();
    this.currentPath = location.href;
  },
  methods: {
    getImageDetail(id){
      globalService_GetDesignDetail({
        id: id,
      })
      .then((response) => {
        this.imageDetail = response.data.result;
        new BeerSlider(document.getElementById("beer-slider"));
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getHouseLayout(){
      globalService_GetHouseLayout().then((response) => {
        this.houseLayouts = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    getPrefectures(){
      globalService_GetAllPrefectures().then((response) => {
        this.prefectures = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
    },
    onCopy(){
      this.$toasted.show('URLをコピーしました', );
    },
    openEditModal(){
      this.title = this.imageDetail.title;
      this.text = this.imageDetail.text;
      this.tag = this.imageDetail.tag;
      this.prefecture_id = this.imageDetail.prefecture_id ? this.imageDetail.prefecture_id : 0;
      this.house_layout_id = this.imageDetail.house_layout_id ? this.imageDetail.house_layout_id : 0;
      this.$bvModal.show('edit');
    },
    closeEditModal(){
      this.$bvModal.hide('edit');
    },
    async update(e){
      e.preventDefault();
      const accessToken = localStorage.getItem('_accessToken');
      console.log(this.prefecture_id)
      console.log(this.house_layout_id)
      await cus_stagingService_PostDesignDetail({
        id: this.$route.params.id,
        title: this.title, 
        text: this.text ? this.text : undefined,
        tag: this.tag ? this.tag : undefined,
        prefecture_id: this.prefecture_id === 0 ? undefined : this.prefecture_id,
        house_layout_id: this.house_layout_id === 0 ? undefined : this.house_layout_id,
        token: accessToken,
      })
      .then((response) => {
        this.getImageDetail(this.$route.params.id);
        this.$bvModal.hide('edit');
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }
}
</script>

<style scoped>
  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .bl_content{
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 50px;
    max-width: 1000px;
    width: 100%;
    height: 500px;
    border-radius: 20px;
    background: #f5f5f5;
  }

  .bl_content .beer-slider{
    width: 60%;
    height: 100%;
  }

  .bl_content .beer-slider .image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .bl_content .beer-slider .beer-reveal .image{
    width: 200%;
  }

  .bl_content .bl_image_text{
    width: 40%;
    margin: 0 20px;
  }

  .bl_content .bl_image_text .bl_link{
    display: flex;
  }

  @media only screen and (max-width: 991px) {
    .bl_content{
      flex-direction: column;
      width: 90%;
      height: 600px;
      margin: 24px auto 0;
    }

    .bl_content .beer-slider{
      width: 100%;
      height: 60%;
    }

    .bl_content .beer-slider .image{
      border-top-right-radius: 20px;
      border-bottom-left-radius: 0;
    }

    .bl_content .bl_image_text{
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      margin-top: 20px;
    }

    .bl_content .bl_image_text .bl_text{
      text-align: center;
    }

    .bl_content .bl_image_text .bl_link{
      justify-content: center;
      margin-top: 20px;
    }
  }

  .bl_content .bl_image_text .social_img{
    object-fit: contain;
  }

  .bl_content .bl_image_text .btn-copy{
    width: 100px;
    transition: all 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .bl_content .bl_image_text .btn-copy:hover{
    background: #757575;
    color: #fff;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .btn_edit{
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    width: 100%;
  }

  .btn_edit .btn{
    width: 220px;
  }

  .bl_modal_btn{
    display: flex;
    justify-content: center;
    margin-top: 80px;
  }

  .bl_modal_btn .btn{
    width: 220px;
  }
</style>